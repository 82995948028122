import "./App.css";
import HomePage from "./pages/HomePage";
import Right from "./components/Right";
import Middle from "./components/Middle";
import ParticleHeaderBg from "./components/ParticleHeaderBg";
import JourneyTimeline from "./components/JourneyTimeline";
import Semantic from "./components/Semantic";
import SkillsShowcase from "./components/Skills";
import Projects from "./components/Projects";
import WhereToFindMe from "./components/WhereToFindMe";

function App() {
  return (
    <>
    {/* <Semantic/> */}
      <ParticleHeaderBg />
      <div className="flex justify-center">
        <JourneyTimeline />
      </div>
        <SkillsShowcase />
        <Projects/>
        <WhereToFindMe/>
      {/* <div className=" bg-[#F8F9FA] h-[100%] w-[100vw] py-[10px] px-[10px] lg:px-[100px]">
      <div className='grid grid-cols-1 gap-3 lg:grid-cols-4'>

      <HomePage/>
      <div className='col-span-2 bg-white rounded-xl'>
        <img src='/tech.gif' className='rounded-[20px] h-[300px] w-full border-gray-200 border hidden lg:block'/>
        <Middle/>
      </div>
      <div className='bg-white rounded-lg'>
        <Right/>
      </div>
      </div>
    </div> */}
    </>
  );
}

export default App;
