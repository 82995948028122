import React, { useEffect, useState } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import useSound from 'use-sound';
import { ReactTyped as Typed } from "react-typed";

import boopSfx from "./tp.mp3";
import JourneyTimeline from "./JourneyTimeline";

export default function ParticleHeaderBg() {
    const [play, { stop}] = useSound(boopSfx,{ volume: 1 });
    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        // console.log(container);
    };

    useEffect(() => {
        const audio = new Audio(boopSfx);
        audio.muted = true;
        audio.play().then(() => {
            audio.muted = false; 
        });
    }, []);

    return (
        <>
            <div className="relative">
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    className="particles-2-css"
                    options={{
                        background: {
                            position: "50% 50%",
                            repeat: "no-repeat",
                            size: "cover",
                            color: "#000"
                        },
                        fullScreen: {
                            enable: true
                        },
                        interactivity: {

                            modes: {
                                bubble: {
                                    distance: 400,
                                    duration: 2,
                                    opacity: 0.8,
                                    size: 10,
                                    divs: {
                                        distance: 200,
                                        duration: 0.4,
                                        mix: false,
                                        selectors: [],
                                    },
                                },
                                grab: {
                                    distance: 400,
                                },
                                repulse: {
                                    divs: {
                                        distance: 200,
                                        duration: 0.4,
                                        factor: 100,
                                        speed: 1,
                                        maxSpeed: 50,
                                        easing: "ease-out-quad",
                                        selectors: [],
                                    },
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: {
                                    value: "#000",
                                },
                                distance: 150,
                                opacity: 0.4,
                            },
                            move: {
                                attract: {
                                    rotate: {
                                        x: 600,
                                        y: 1200,
                                    },
                                },
                                enable: true,
                                outModes: {
                                    bottom: "out",
                                    left: "out",
                                    right: "out",
                                    top: "out",
                                },
                            },
                            number: {
                                density: {
                                    enable: true,
                                },
                                value: 80,
                            },
                            opacity: {
                                random: {
                                    enable: true,
                                },
                                value: {
                                    min: 0.1,
                                    max: 1,
                                },
                                animation: {
                                    enable: true,
                                    speed: 1,
                                    minimumValue: 0.2,
                                },
                            },
                            rotate: {
                                random: {
                                    enable: true,
                                },
                                animation: {
                                    enable: true,
                                    speed: 5,
                                },
                                direction: "random",
                            },
                            shape: {
                                options: {
                                    character: {
                                        fill: false,
                                        font: "Verdana",
                                        style: "",
                                        value: "*",
                                        weight: "400",
                                    },
                                    char: {
                                        fill: false,
                                        font: "Verdana",
                                        style: "",
                                        value: "*",
                                        weight: "400",
                                    },
                                    polygon: {
                                        sides: 5,
                                    },
                                    star: {
                                        sides: 5,
                                    },
                                    image: [
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/yii/yii-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/antdesign/antdesign-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nestjs/nestjs-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nextjs/nextjs-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apachekafka/apachekafka-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redux/redux-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/socketio/socketio-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/swagger/swagger-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                    ],
                                    images: [
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/yii/yii-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/antdesign/antdesign-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nestjs/nestjs-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nextjs/nextjs-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apachekafka/apachekafka-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redux/redux-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/socketio/socketio-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/swagger/swagger-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original-wordmark.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                        {
                                            src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
                                            width: 20,
                                            height: 20,
                                        },
                                    ],
                                },
                                type: "image",
                            },
                            size: {
                                value: 50,
                                animation: {
                                    speed: 40,
                                    minimumValue: 0.1,
                                },
                            },
                            stroke: {
                                width: "100px",
                                color: {
                                    value: "#000000",
                                    animation: {
                                        h: {
                                            count: 0,
                                            enable: false,
                                            offset: 0,
                                            speed: 1,
                                            decay: 0,
                                            sync: true,
                                        },
                                        s: {
                                            count: 0,
                                            enable: false,
                                            offset: 0,
                                            speed: 1,
                                            decay: 0,
                                            sync: true,
                                        },
                                        l: {
                                            count: 0,
                                            enable: false,
                                            offset: 0,
                                            speed: 1,
                                            decay: 0,
                                            sync: true,
                                        },
                                    },
                                },
                            },
                        },
                    }}
                />
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    backgroundColor: "transparent",
                    fontFamily: "monospace",
                    zIndex: 10,
                    textAlign: "center",
                    width: "100%",
                    marginTop: "20%"
                }}>
                    <Typed
                        strings={["Kasif Raza"]}
                        typeSpeed={200}
                        backSpeed={50}
                        backDelay={500}
                        onTyping={() => play()}
                        onStart={()=>{
                            play();
                        }}
                        onComplete={() => {
                            stop();
                        }}                        
                        showCursor={false}
                        cursorChar="|"
                        style={{ fontSize: "36px", fontWeight: "bold" }}
                    />
                    <div style={{ fontSize: "24px" }}>
                        <Typed
                            strings={["NodeJs + React Developer"]}
                            typeSpeed={200}
                            backSpeed={50}
                            backDelay={500}
                            onTyping={() => play()}
                            onStart={()=>{
                                play();
                            }}
                            onComplete={() => {
                                stop();
                            }}                            
                            showCursor={false}
                            // cursorChar="|"
                            startDelay={2000}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
