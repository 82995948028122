import React, { useState } from "react";
import { FaJs, FaNodeJs, FaReact, FaGitAlt, FaAws } from "react-icons/fa";
import {
  SiNextdotjs,
  SiNestjs,
  SiMysql,
  SiMongodb,
  SiApachekafka,
  SiRazorpay,
  SiPhp,
} from "react-icons/si";

const SkillsShowcase = () => {
  const [activeTab, setActiveTab] = useState("primary");

  const skillCategories = {
    primary: [
      {
        name: "JavaScript",
        icon: <FaJs className="text-yellow-400" size={40} />,
      },
      {
        name: "Node.js",
        icon: <FaNodeJs className="text-green-600" size={40} />,
      },
      { name: "MySQL", icon: <SiMysql className="text-blue-500" size={40} /> },
      {
        name: "MongoDB",
        icon: <SiMongodb className="text-green-500" size={40} />,
      },
    ],
    frontend: [
      {
        name: "React.js",
        icon: <FaReact className="text-blue-400" size={40} />,
      },
      {
        name: "Next.js",
        icon: <SiNextdotjs className="text-white" size={40} />,
      },
    ],
    intermediate: [
      {
        name: "Kafka",
        icon: <SiApachekafka className="text-white" size={40} />,
      },
      {
        name: "React Native",
        icon: <FaReact className="text-purple-500" size={40} />,
      },
      { name: "NestJS", icon: <SiNestjs className="text-red-500" size={40} /> },
      { name: "Php", icon: <SiPhp className="text-blue-500" size={40} /> },
    ],
    tools: [
      { name: "Git", icon: <FaGitAlt className="text-orange-500" size={40} /> },
      { name: "AWS", icon: <FaAws className="text-[#F59607]" size={40} /> },
    ],
  };

  const tabs = [
    { id: "primary", label: "Primary Skills" },
    { id: "frontend", label: "Frontend Skills" },
    { id: "intermediate", label: "Intermediate Skills" },
    { id: "tools", label: "Tools & Technologies" },
  ];

  return (
    <div className="absolute z-10 top-[220%] md:top-[200%] flex justify-center w-full">
      <div className="max-w-6xl">
        <h1 className="mb-8 text-2xl font-bold text-center text-white text-wrap">
            What I Do Best: Skills & Expertise
        </h1>

        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {tabs.map((tab) => (
            <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-6 py-3 rounded-full font-medium transition-all duration-300 ${
              activeTab === tab.id
                ? "bg-[orangered] text-white shadow-lg transform scale-105"
                : "bg-[#0b0909] text-gray-300 hover:bg-gray-600 backdrop-blur-md"
            }`}
            aria-selected={activeTab === tab.id}
            role="tab"
          >
            {tab.label}
          </button>
          
          ))}
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          {skillCategories[activeTab].map((skill, index) => (
            <div
              key={index}
              className="p-6 transition-all duration-300 transform bg-transparent rounded-xl hover:scale-105 hover:shadow-xl"
              style={{
                animation: `fadeIn 0.5s ease-out ${index * 0.1}s forwards`,
                opacity: 0,
              }}
            >
              <div className="flex flex-col items-center space-y-4">
                <div className="p-4 bg-[#211b1b] rounded-full">{skill.icon}</div>
                <h3 className="text-xl font-semibold text-white">
                  {skill.name}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>

  );
};

export default SkillsShowcase;
