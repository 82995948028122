import React from "react";

const HomePage = () => {
	return (
		<>
			<div className="bg-[#fff] rounded-lg">
				<div className="flex justify-center">
					<div className="flex justify-center w-[62px] h-[62px] rounded-[50%] border-2 border-solid border-[#11e511]">
						<img
							src="/k.png"
							alt="kasif-profile-photo"
							className="rounded-[50%] w-full h-full"
						/>
					</div>
				</div>
				<div className="flex justify-center">
					<h1 className="text-xl text-slate-500 font-mono">Kasif Raza</h1>
				</div>
				<div className="flex justify-center">
					<h3 className="text-sm text-slate-600 font-mono">NodeJS Developer</h3>
				</div>
				<div className="flex justify-center">
					<a
						href="https://indicsoft.com/"
						className="text-[10px] text-slate-600 font-mono"
					>
						@ Indicsoft Technologies Private Limited
					</a>
				</div>
				<div className=" bg-gray-100 border border-gray-200 m-5 rounded-md">
					<p className="text-sm font-mono font-medium p-2">Professional Summary</p>
					<p className="text-sm text-gray-500 font-extralight px-2">
						Node.js Backend Developer with 2+ Years of Hands-on Experience | Proficient
						in Building Scalable Solutions | Knowledge of React.js for Seamless
						Full-Stack Development.
					</p>
				</div>
				<>
					{/* Technical Skills */}
					<div className="px-4">
						<h2 className="mb-2 text-md font-semibold font-mono text-gray-500 dark:text-white">
							Technical Skills
						</h2>
						<ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								NodeJS
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								MySQL
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								MongoDB
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								JavaScript
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								ExpressJS
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								React JS
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								Php (Yii-2 MVC Framework)
							</li>
							<li className="flex items-center">
								<svg
									className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
								</svg>
								Git
							</li>
						</ul>
					</div>
				</>
				<div className="bg-gray-50 rounded-md m-5">
					<div className="flex justify-center items-center py-2">
						{/* B.Tech */}
						<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
							B.Tech(CSE)
						</p>
						<p className="text-[10px] text-gray-500 font-extralight px-2">
							2018-2022 <br />
							(Maharishi Dayanand University)
						</p>
					</div>
					<div className="flex justify-center items-center py-2">
						{/* 12th */}
						<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
							XII
						</p>
						<p className="text-[10px] text-gray-500 font-extralight px-2">
							2018 <br />
							Bihar School Examination Board,Patna
						</p>
					</div>
					<div className="flex justify-center items-center py-2">
						{/* 10th */}
						<p className="text-[10px] font-mono font-medium p-2 flex items-center gap-2">
							X
						</p>
						<p className="text-[10px] text-gray-500 font-extralight px-2">
							2016 <br />
							Bihar School Examination Board,Patna
						</p>
					</div>
					
				</div>
			</div>
		</>
	);
};

export default HomePage;
