import { Image, Modal } from "antd";
import React from "react";

const ProjectModal = ({
  title = "Project",
  images=[],
  projectModalOpen,
  setProjectModalOpen,
}) => {
  return (
    <>
      <Modal
        maskClosable
        title={title}
        open={projectModalOpen}
        onCancel={() => setProjectModalOpen(false)}
        className="custom-fullscreen-modal"
        footer={null}
        width="100vw"
        style={{ top: 0, height: "100vh" }}
        bodyStyle={{
          padding: 0,
          height: "calc(100% - 55px)",
          overflow: "auto",
        }}
      >
        <div className="p-5">
          <p className="text-justify text-wrap">
            I will soon share more details about this project, including a live demo link and the source code.
            Thank you for exploring my portfolio.   
          </p>
        </div>
          {
            images.length > 1 && (
              <div className="flex items-center justify-center w-full gap-4 my-5 mt-5">
                {
                  images.map((image, index) => (
                    <Image
                      key={index}
                      src={image}
                      alt={`Project ${index + 1}`}
                      className="object-cover w-full h-full transition duration-300 ease-in-out rounded-md shadow-lg cursor-pointer shadow-gray-500/50 hover:scale-105"
                    />
                  ))
                }
              </div>
            )
          }
      </Modal>

      <style jsx>{`
        .custom-fullscreen-modal .ant-modal-content {
          height: 100vh;
          border-radius: 0;
        }
      `}</style>
    </>
  );
};

export default ProjectModal;
