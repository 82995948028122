import React from "react";
import "./timeline.css";
const JourneyTimeline = () => {
  const timeline = [
    {
      title: "10th Grade Education",
      subtitle: "From: BSEB Patna",
      details: "Passed 10th in 2016.",
    },
    {
      title: "12th Grade Education",
      subtitle: "From: BSEB Patna",
      details: "Completed 12th in 2018.",
    },
    {
      title: "Bachelor of Technology (B.Tech) - CSE",
      subtitle: "From: Maharshi Dayanand University, Rohtak (2018-2022).",
      details:"Graduated in 2022",
    },
    {
      title: "Software Developer Internship",
      subtitle: " @ Indicsoft Technologies Pvt Ltd, Noida",
      details:"Joined as an intern in October 2021 and completed the internship in May 2022 (6 months).",
    },
    {
      title: "Software Developer",
      subtitle: " @ Indicsoft Technologies Pvt Ltd, Noida",
      experience: `Experience: ${calculateExperience("2022-04-01")}`,
      details:"Joined as a Software Developer and currently working since April 2022.",
    },
  ];

  function calculateExperience(startDate) {
    const indiaTimeZone = 'Asia/Kolkata';
    const start = new Date(new Date(startDate).toLocaleString("en-US", { timeZone: indiaTimeZone }));
    const now = new Date(new Date().toLocaleString("en-US", { timeZone: indiaTimeZone }));

    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();

    if (months < 0) {
        years -= 1;
        months += 12;
    }

    return `${years} year${years > 1 ? 's' : ''} ${months} month${months > 1 ? 's' : ''}`;
}
  return (
    <>
      <div className="absolute z-10 timeline">
        <div className="outer">
          <h1 className="text-2xl font-bold text-gray-300">Where I started</h1>
          {timeline.map((item, index) => {
            return (
              <div className="card" key={index}>
                <div className="info">
                  <h3 className="title text-wrap">{item.title}</h3>
                  {/* subtitle */}
                  <div className="text-sm text-[#ec7044] subtitle text-wrap py-2">{item.subtitle}</div>
                  {
                    item.experience && (
                      <div className="text-sm text-[#44fd9b] subtitle text-wrap py-2">{item.experience}</div>)
                  }
                  <p className="text-sm text-wrap">{item.details}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default JourneyTimeline;
