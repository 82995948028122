import React, { useState } from "react";
import { FaLinkedin, FaEnvelope, FaPhone } from "react-icons/fa";
import { motion } from "framer-motion";

const WhereToFindMe = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const contactInfo = [
        {
            id: 1,
            icon: <FaLinkedin className="text-3xl" />,
            label: "LinkedIn",
            value: "linkedin.com/in/kasif-raza-070538170",
            link: "https://www.linkedin.com/in/kasif-raza-070538170/"
        },
        {
            id: 2,
            icon: <FaEnvelope className="text-3xl" />,
            label: "Email",
            value: "kasifraza910@gmail.com",
            link: "mailto:kasifraza910@gmail.com"
        },
        {
            id: 3,
            icon: <FaPhone className="text-3xl" />,
            label: "Phone",
            value: "+91 9873287018",
            link: "tel:+91 9873287018"
        }
    ];

    return (
        <div className="absolute z-[10] top-[460%] md:top-[310%] w-screen lg:w-full">
            <section className="relative w-full overflow-hidden">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="relative z-10"
                >
                    <h2 className="mb-12 text-4xl font-bold text-center text-white/90 text-wrap">
                        Where to Find Me
                    </h2>
                    <div className="grid grid-cols-1 gap-4 px-2 mb-4">
                        <div className="p-6 mx-auto border shadow-xl bg-white/10 rounded-xl backdrop-blur-md border-white/20">
                            {/* <h3 className="mb-4 text-2xl font-semibold text-white/90 text-wrap">
                                Contact Information
                            </h3> */}
                            <div className="space-y-6">
                                {contactInfo.map((item, index) => (
                                    <motion.a
                                        key={item.id}
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center gap-4 transition-colors duration-300 text-white/80 hover:text-white"
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                        whileHover={{ scale: 1.02 }}
                                        aria-label={`${item.label}: ${item.value}`}
                                    >
                                        <motion.div
                                            animate={{
                                                rotate: hoveredIndex === index ? 360 : 0
                                            }}
                                            transition={{ duration: 0.5 }}
                                        > 
                                            {item.icon}
                                        </motion.div>
                                        <div>
                                            <p className="font-medium text-wrap">{item.label}</p>
                                            <p className="text-sm opacity-80 text-wrap">{item.value}</p>
                                        </div>
                                    </motion.a>
                                ))}
                            </div>
                        </div>
                    </div>
                </motion.div>
            </section>
        </div>
    );
};

export default WhereToFindMe;