import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ProjectModal from "./ProjectModal";

const Projects = () => {
  const [projectModalData, setProjectModalData] = useState(null);
  const plans = [
    {
      id: 1,
      title: "Unified Media Upload Service",
      image: "/media-service.webp",
    },
    {
      id: 2,
      title: "Hajj and Umrah Package Booking Platform Rest API's in NodeJS & MongoDB",
      image: "/hajj-umrah.webp",
    },
    {
      id: 3,
      title: "Swachh Bharat Mission: Microservices Architecture",
      image: "/swachh.webp",
    },
    {
      id: 4,
      title: "BachatWala - Grocery Price Comparison Mobile App",
      image: "https://res.cloudinary.com/dyvhryjbe/image/upload/v1733452276/bachatwala/bachatwala-home-page.jpg",
      images:[
        "https://res.cloudinary.com/dyvhryjbe/image/upload/v1733452276/bachatwala/bachatwala-home-page.jpg",
        "https://res.cloudinary.com/dyvhryjbe/image/upload/v1733452276/bachatwala/bachatwala-searchbar-home-page.jpg",
        "https://res.cloudinary.com/dyvhryjbe/image/upload/v1733452276/bachatwala/bachatwala-searched-products-tab.jpg",
        "https://res.cloudinary.com/dyvhryjbe/image/upload/v1733452275/bachatwala/bachatwala-comparison-page.jpg"
      ]
    },
    {
      id: 5,
      title: "No Code Low Code Platform using Nocobase(OpenSource)",
      image: "/nocobase.jpeg",
    },
    
  ];
  return (
    <>
      <div className="w-full absolute z-[10] top-[400%] md:top-[250%]">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          initialSlide={2}
          spaceBetween={20}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 10,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={false}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="w-full mySwiper-projects"
        >
          {plans.map((plan) => (
            <SwiperSlide key={plan.id} onClick={() => setProjectModalData(plan)} >
              <img
                src={plan.image}
                alt={plan.title}
                className="object-cover w-full h-64 rounded-lg"
              />
              <p className="mt-2 text-center text-gray-100 text-wrap">{plan.title}</p>
            </SwiperSlide>

          ))}
          <div className="swiper-button-prev">
            <FaArrowLeft />
          </div>
          <div className="swiper-button-next">
            <FaArrowRight />
          </div>
        </Swiper>
      </div>

      {projectModalData && (
        <ProjectModal
          title={projectModalData.title}
          images={projectModalData.images || [projectModalData.image]}
          projectModalOpen={!!projectModalData}
          setProjectModalOpen={() => setProjectModalData(null)}
        />
      )}
      <style jsx>{`
        .mySwiper-projects {
          padding: 30px 0;
        }
        .mySwiper-projects .swiper-slide {
          width: 300px;
          height: 100%;
          min-height: 300px;
        }
        .mySwiper-projects .swiper-button-prev,
        .swiper-button-next {
          color: #60a5fa;
        }
      `}</style>
    </>
  );
};

export default Projects;
